<template>
  <div>
    <div>
      <Navbar/>
    </div>
    <div class="root_">
      <div
        v-for="(item, indx) in getAllPackageDetails"
        :key="indx"
        class="root_box"
      >
        <!-- <div>{{item}}</div> -->
        <SpecialComp :value="item" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState, mapActions } from "vuex";
import { BRow, BDropdown, BButton } from "bootstrap-vue";
import SpecialComp from "./SpecialComp.vue";
import Navbar from "@/yicpages/navbar/Navbar.vue";
// import ConsolidatedItems from "./ConsolidatedItems.vue";

export default {
  components: {
    SpecialComp,
    BRow,
    BDropdown,
    BButton,
    Navbar
},
  data() {
    return {
      // viewItems: false,
      packageId: null,
    };
  },
  computed: mapState({
    getAllPackageDetails: (state) => {
      return state.shippingPackages.itemsToBeShipped;
    },
  }),
  mounted() {
    this.packageId = this.$route.params.id;
    console.log(this.$route.params.id);
    this.getShippedItems(this.packageId);
  },
  methods: {
    ...mapActions({
      getShippedItems: "shippingPackages/getShippedItems2",
    }),
  },
};
</script>

<style scoped>
.row {
  display: flex;
  justify-content: space-around;
}
.customDropdown {
  color: #4385f5;
}
.newbox {
  border: 1px dashed #4385f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  cursor: pointer;
}
.root_box {
  color: #f8f8f8;
}
</style>